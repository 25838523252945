<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                {{ $t('ui.filters') }}
            </h5>
        </b-card-header>

        <b-card-body>
            <b-form class="d-flex" @submit.prevent="applyFilters">
                <b-row class="w-100">
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>
                            {{ $t('user.role') }}
                        </label>

                        <v-select
                            v-model="modelRoleFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="roleOptions"
                            class="w-100"
                            label="name"
                            :reduce="(option) => option.id"
                            :searchable="false"
                        />
                        <!-- :value="roleFilter" -->
                        <!-- @input="
                                (roleID) => $emit('update:roleFilter', roleID)
                            " -->
                    </b-col>

                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>
                            {{ $t('user.strategy') }}
                        </label>

                        <v-select
                            v-model="modelStrategyFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="strategiesOptions"
                            class="w-100"
                            label="name"
                            :reduce="(option) => option.id"
                            :searchable="false"
                        />
                    </b-col>

                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>
                            {{ $t('user.subscription') }}
                        </label>

                        <v-select
                            v-model="modelSubscriptionFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="subscriptionOptions"
                            class="w-100"
                            label="name"
                            :reduce="(option) => option.id"
                        />
                    </b-col>
                </b-row>

                <b-button
                    variant="primary"
                    type="submit"
                    class="align-self-end ml-1"
                    :disabled="isFiltersApplied"
                >
                    {{ $t('ui.apply') }}
                </b-button>
            </b-form>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BForm,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        vSelect,
        BButton,
        BForm,
    },

    props: {
        roleFilter: {
            type: [Number, null],
            default: null,
        },

        strategyFilter: {
            type: [Number, null],
            default: null,
        },

        subscriptionFilter: {
            type: [Number, null],
            default: null,
        },

        roleOptions: {
            type: Array,
            required: true,
        },

        strategiesOptions: {
            type: Array,
            required: true,
        },

        subscriptionOptions: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            modelRoleFilter: this.roleFilter,
            modelStrategyFilter: this.strategyFilter,
            modelSubscriptionFilter: this.subscriptionFilter,
        };
    },

    computed: {
        isFiltersApplied() {
            const isRoleFilterApplied =
                this.modelRoleFilter === this.roleFilter;
            const isStrategyFilterApplied =
                this.modelStrategyFilter === this.strategyFilter;
            const isSubscriptionFilterApplied =
                this.modelSubscriptionFilter === this.subscriptionFilter;

            return (
                isRoleFilterApplied &&
                isStrategyFilterApplied &&
                isSubscriptionFilterApplied
            );
        },
    },

    watch: {
        modelRoleFilter(value) {
            this.resetFilters(value);
        },

        modelStrategyFilter(value) {
            this.resetFilters(value);
        },

        modelSubscriptionFilter(value) {
            this.resetFilters(value);
        },
    },

    methods: {
        resetFilters(value) {
            if (value !== null) return;

            this.applyFilters();
        },

        applyFilters() {
            this.$emit('update:roleFilter', this.modelRoleFilter);
            this.$emit('update:strategyFilter', this.modelStrategyFilter);
            this.$emit(
                'update:subscriptionFilter',
                this.modelSubscriptionFilter
            );
        },
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
