<template>
    <div class="users-list">
        <!-- Filters -->
        <users-list-filters
            :role-filter.sync="roleFilter"
            :strategy-filter.sync="strategyFilter"
            :subscription-filter.sync="subscriptionFilter"
            :role-options="roleOptions"
            :strategies-options="strategiesOptions"
            :subscription-options="subscriptionOptions"
        />

        <!-- Table Container Card -->
        <b-card no-body class="users-card mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>
                            {{ $t('ui.perPage') }}
                        </label>

                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />

                        <label>
                            {{ $t('ui.entries') }}
                        </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div
                            class="d-flex align-items-center justify-content-end"
                        >
                            <b-form-input
                                :value="searchQuery"
                                class="d-inline-block"
                                :placeholder="$t('ui.search')"
                                @input="debouncedUpdateSearchQuery"
                            />
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refUserListTable"
                class="users-table position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('ui.searchNotFound')"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Email -->
                <template #cell(email)="data">
                    <b-media
                        vertical-align="center"
                        class="d-flex align-items-center"
                    >
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="data.item.avatar"
                                :text="avatarText(data.item.email)"
                                :variant="`light-${data.item.role.theme}`"
                                :to="{
                                    name: 'users-view',
                                    params: { id: data.item.id },
                                }"
                            />
                        </template>

                        <b-link
                            :to="{
                                name: 'users-view',
                                params: { id: data.item.id },
                            }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ data.item.email }}
                        </b-link>
                    </b-media>
                </template>

                <!-- Column: Role -->
                <template #cell(role)="data">
                    <div class="text-nowrap">
                        <feather-icon
                            :icon="data.item.role.icon"
                            size="18"
                            class="mr-50"
                            :class="`text-${data.item.role.theme}`"
                        />
                        <span class="align-text-top text-capitalize">{{
                            data.item.role.name
                        }}</span>
                    </div>
                </template>

                <!-- Column: Subscription -->
                <template #cell(subscription)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveUserSubscriptionVariant(
                            subscriptionBadgeText(data.item.subscription)
                        )}`"
                        class="text-capitalize"
                    >
                        {{ subscriptionBadgeText(data.item.subscription) }}
                    </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item
                            :to="{
                                name: 'users-view',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">
                                {{ $t('user.view') }}
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            @click="openConfirmModal(data.item.id)"
                        >
                            <feather-icon icon="XIcon" />
                            <span class="align-middle ml-50">
                                {{ $t('user.disableAutorenewal') }}
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="RotateCcwIcon" />
                            <span class="align-middle ml-50">
                                {{ $t('user.resetDeviceBinding') }}
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{
                                name: 'users-edit',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">
                                {{ $t('user.edit') }}
                            </span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

                <template #table-busy>
                    <div class="text-center text-danger centered">
                        <b-spinner variant="primary" class="align-middle" />
                    </div>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <i18n class="text-muted" tag="span" path="ui.showing">
                            <template v-slot:from>
                                {{ dataMeta.from }}
                            </template>

                            <template v-slot:to>
                                {{ dataMeta.to }}
                            </template>

                            <template v-slot:of>
                                {{ dataMeta.of }}
                            </template>
                        </i18n>
                    </b-col>

                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination-nav
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :number-of-pages="lastPage"
                            :per-page="perPage"
                            :link-gen="() => {}"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination-nav>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <modals-confirm-action
            :modal-i-d="modalCancelAutoRenewalID"
            @cancel="resetSelectedID"
            @submit="cancelAutoRenewal"
        >
            <template #title>
                {{ $t('user.disableAutorenewal') }}
            </template>

            <template #message>
                {{ $t('ui.areYouSure') }}
            </template>
        </modals-confirm-action>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPaginationNav,
    BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';
import { subscriptionBadgeText, avatarText } from '@/utils/formatter';
import useStrategies from '@/views/strategies/useStrategies';
import ModalsConfirmAction from '@/views/components/modals/ModalsConfirmAction.vue';
import usePositionedToast from '@/utils/usePositionedToast';
import useModalsConfirm from '@/views/components/modals/useModalsConfirm';
import UsersListFilters from './UsersListFilters.vue';
import useUsers from '../useUsers';
// import UserListAddNew from './UserListAddNew.vue';

export default {
    components: {
        UsersListFilters,
        // UserListAddNew,

        BCard,
        BRow,
        BCol,
        BFormInput,
        // BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPaginationNav,
        BSpinner,

        vSelect,

        ModalsConfirmAction,
    },

    computed: {
        roleOptions() {
            return this.$store.getters['app-roles/GET_ROLES'] || [];
        },

        strategiesOptions() {
            return this.$store.getters['app-strategies/GET_STRATEGIES'] || [];
        },

        subscriptionOptions() {
            const subscriptions =
                this.$store.getters['app-subscriptions/GET_SUBSCRIPTIONS'] ||
                [];

            return subscriptions.map((subscription) => {
                return {
                    id: subscription.id,
                    name:
                        subscription.days < 10
                            ? 'Trial'
                            : `${subscription.days} days`,
                };
            });
        },
    },

    created() {
        this.debouncedUpdateSearchQuery = this.lodash.debounce((query) => {
            this.updateSearchQuery(query);
        }, 1000);
    },

    methods: {
        updateSearchQuery(value) {
            this.searchQuery = value;
        },

        cancelAutoRenewal() {
            this.cancelAutoRenewalByUserID(this.selectedID)
                .then((response) => {
                    this.$refs.refUserListTable.refresh();

                    this.showNotification({ title: response?.data?.message });
                })
                .finally(() => {
                    this.resetSelectedID();
                });
        },
    },

    setup(_, context) {
        const modalCancelAutoRenewalID = 'modalCancelAutoRenewal';
        const {
            selectedID,
            resetSelectedID,
            openConfirmModal,
        } = useModalsConfirm(context, modalCancelAutoRenewalID);

        const isAddNewUserSidebarActive = ref(false);

        const { fetchStrategies } = useStrategies();
        const { showNotification } = usePositionedToast();

        const {
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchUsersList,
            cancelAutoRenewalByUserID,
            lastPage,

            // UI
            resolveUserSubscriptionVariant,

            // Extra Filters
            roleFilter,
            strategyFilter,
            subscriptionFilter,
        } = useUsers();

        fetchStrategies();

        return {
            modalCancelAutoRenewalID,
            selectedID,
            resetSelectedID,
            openConfirmModal,

            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchUsersList,
            cancelAutoRenewalByUserID,
            lastPage,

            // Filter
            avatarText,
            subscriptionBadgeText,

            // UI
            resolveUserSubscriptionVariant,

            // Extra Filters
            roleFilter,
            strategyFilter,
            subscriptionFilter,

            showNotification,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.users-table {
    height: 100% !important;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
